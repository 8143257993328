import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './TopicList.css';
import { AuthContext } from '../contexts/AuthContext';

const generateUrl = (name) => {
  if (!name) {
    console.error('Name is undefined or null:', name);
    return ''; // Return a default value or handle it as necessary
  }
  return name.toLowerCase().replace(/ /g, '-');
};

const TopicList = () => {
  const [topics, setTopics] = useState([]);
  const { user } = useContext(AuthContext);
  const PREVIEW_COUNT = 12;

  console.log('Current user:', user);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${API_URL}/api/topics`);
        setTopics(response.data);
      } catch (error) {
        console.error('Error fetching topics:', error);
      }
    };

    fetchTopics();
  }, []);

  // Show all topics but mark them as preview/locked
  return (
    <div className="container">
      <Helmet>
        <title>Topics - The Other Side</title>
        <meta name="description" content="Explore a variety of topics with balanced perspectives on major political and social issues." />
        <meta name="keywords" content="topics, balanced perspectives, political issues, social issues, curated content" />
        <link rel="canonical" href="https://www.theotherside.media/topics" />
      </Helmet>
      <div className="tagline-container">
        <h1 className="tagline">Hear From The Other Side</h1>
      </div>
      <div className="topics-container position-relative">
        <div className="topic-grid">
          {topics.map((topic, index) => (
            <div
              className={`topic-item ${index >= PREVIEW_COUNT && !user ? 'locked' : ''}`}
              key={topic._id}
            >
              <h5>
                <Link
                  to={`/topics/${generateUrl(topic.name)}`}
                  className={index >= PREVIEW_COUNT && !user ? 'disabled-link' : ''}
                  onClick={e => {
                    if (index >= PREVIEW_COUNT && !user) e.preventDefault();
                  }}
                >
                  {topic.name}
                </Link>
              </h5>
              <hr />
              <ul className="list-unstyled">
                {Array.isArray(topic.subtopics) && topic.subtopics.map(subtopic => (
                  <li key={subtopic._id}>
                    <Link
                      to={`/subtopics/${subtopic._id}`}
                      className={`subtopic-link ${index >= PREVIEW_COUNT && !user ? 'disabled-link' : ''}`}
                      onClick={e => {
                        if (index >= PREVIEW_COUNT && !user) e.preventDefault();
                      }}
                    >
                      {subtopic.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {!user && topics.length > PREVIEW_COUNT && (
          <div className="registration-wall-overlay">
            <div className="registration-wall-content">
              <h3>Want to access all topics?</h3>
              <p>Register or log in to unlock {topics.length - PREVIEW_COUNT} more topics!</p>
              <div className="registration-wall-buttons">
                <Link to="/register" className="btn btn-primary">Register Now</Link>
                <Link to="/login" className="btn btn-outline-primary">Log In</Link>
              </div>
            </div>
          </div>
        )}
      </div>

      <br></br>
      <div className="missing-something-container">
        <p className="missing-something">
          Think we're missing something? Let us know <a href="https://docs.google.com/forms/d/e/1FAIpQLSdklLXQ6Xn8bZ6bBQrQlzHYILyjPEJeEfIZsduw2buXOOTwcw/viewform?usp=header" target="_blank" rel="noopener noreferrer">here</a>
        </p>
      </div>
    </div>
  );
};

export default TopicList;
